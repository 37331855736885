import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import GalleryCardList from '../components/GalleryCardList';
import SEO from '../components/SEO';

const AboutStyles = styled.div`
  display: grid;
  grid-template-columns: 60% auto;
  grid-gap: 2rem;
  padding: 4rem 0;
  justify-content: center;

  @media screen and (max-width: 790px) {
    grid-template-columns: 1fr;
  }

  ul {
    list-style-type: none;
    margin: 0;
    line-height: 2rem;
    padding: 2rem 0 0;
  }

  li {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style='fill: ${(
        props
      ) =>
        encodeURIComponent(props.svgColor) ||
        'black'};transform: ;msFilter:;'%3E%3Cpath d='M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z'%3E%3C/path%3E%3Cpath d='M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat left 0.4rem;
    padding-left: 3rem;
    margin-bottom: 1.5rem;
  }
`;

function ServiceListItem({ service }) {
  return <li>{service}</li>;
}

export default function HomePage({ data }) {
  const galleries = data.galleries.nodes;
  const { siteSettings } = data;

  const serializers = {
    types: {
      code: (props) => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  };

  let aboutContent;

  if (siteSettings?.aboutContent) {
    aboutContent = (
      <BlockContent
        blocks={siteSettings?.aboutContent}
        serializers={serializers}
      />
    );
  } else {
    aboutContent = (
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Id porta nibh
        venenatis cras sed felis eget velit. Venenatis tellus in metus vulputate
        eu scelerisque felis. Vitae sapien pellentesque habitant morbi tristique
        senectus. Vulputate mi sit amet mauris commodo. A diam maecenas sed enim
        ut. Pellentesque dignissim enim sit amet venenatis urna. Vitae suscipit
        tellus mauris a diam maecenas. Eu mi bibendum neque egestas. Ac tortor
        dignissim convallis aenean. Nibh tellus molestie nunc non. Enim sit amet
        venenatis urna.
      </p>
    );
  }

  return (
    <>
      <SEO title="Home" />
      <GalleryCardList galleries={galleries} />
      <AboutStyles svgColor={siteSettings?.foregroundColor?.hex}>
        <div>
          <h1>{siteSettings?.aboutHeading || 'Our Story'}</h1>
          {aboutContent}
        </div>
        <div>
          <ul>
            {siteSettings?.serviceList ? (
              siteSettings?.serviceList.map((service, index) => (
                <ServiceListItem key={index} service={service} />
              ))
            ) : (
              <>
                <li>Commissioned artwork</li>
                <li>Always made to order</li>
                <li>Proud of our local roots</li>
              </>
            )}
          </ul>
        </div>
      </AboutStyles>
    </>
  );
}

export const query = graphql`
  query {
    galleries: allSanityGallery {
      nodes {
        galleryName
        slug {
          current
        }
        featuredImage {
          image {
            asset {
              gatsbyImageData(
                width: 240
                height: 320
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    siteSettings: sanitySiteSettings {
      aboutContent: _rawAboutContent(resolveReferences: { maxDepth: 10 })
      aboutHeading
      serviceList
      foregroundColor {
        hex
      }
    }
  }
`;
