import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const GalleryCardsStyles = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 4rem;

  img {
    display: block;
  }
`;

const GalleryCardStyles = styled.div`
  display: inline-block;
  margin: 2rem;
  filter: drop-shadow(0px 50px 100px var(--dark-grey));
`;

const GalleryNameStyles = styled.div`
  background-color: var(--card-color);
  color: var(--font-color);
  font-family: 'Amatic SC';
  text-align: center;
  padding: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  font-size: 2rem;
  font-weight: bold;
`;

function SingleGalleryCard({ gallery }) {
  const image = gallery?.featuredImage?.image?.asset?.gatsbyImageData;

  return (
    <Link to={gallery.slug.current}>
      <GalleryCardStyles>
        {image ? (
          <GatsbyImage
            image={image}
            alt="This is some alt work"
            style={{ display: 'block' }}
          />
        ) : (
          <img
            src="/default-featured-gallery.png"
            alt="default blank gallery image"
          />
        )}
        <GalleryNameStyles>{gallery.galleryName}</GalleryNameStyles>
      </GalleryCardStyles>
    </Link>
  );
}

export default function GalleryCardList({ galleries }) {
  return (
    <GalleryCardsStyles>
      {galleries.map((gallery, index) => (
        <SingleGalleryCard key={index} gallery={gallery} />
      ))}
    </GalleryCardsStyles>
  );
}
